const SAVING = 'SAVING'
const UNSAVED = 'UNSAVED'
const UNCHANGED = 'UNCHANGED'

export default class NewEditable {
	constructor() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false
	}

	/**
	 * Get the editable field's status.
	 * @return {String}
	 */
	get status() {
		if (!this.editing) return ''

		if (this.saving) return SAVING

		if (this.changed) return UNSAVED

		return UNCHANGED
	}

	/**
	 * Start editing and set the original value.
	 * @param {String} value
	 */
	edit(value) {
		this.value = value || ''
		this.original = value || ''
		this.editing = true
		this.changed = false // Reset the changed flag
	}

	onInput(event) {
		// Limit the input to 250 characters
		const sanitizedValue = event.target.value
			.replace(/[^a-zA-Z0-9\-:.,/\s]/g, '')
			.slice(0, 250)
		event.target.value = sanitizedValue
		this.value = sanitizedValue
		this.changed = this.original !== this.value
	}

	/**
	 * Reset the editable field back to its original state.
	 */
	reset() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false
	}
}
