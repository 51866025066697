<template>
	<svg
		width="33"
		height="34"
		viewBox="0 0 33 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		class="multipleMRN"
	>
		<path
			d="M13.4167 12.4092H5.75004C5.24171 12.4092 4.7542 12.6111 4.39475 12.9706C4.03531 13.33 3.83337 13.8175 3.83337 14.3258V29.6592C3.83337 30.1675 4.03531 30.655 4.39475 31.0145C4.7542 31.3739 5.24171 31.5758 5.75004 31.5758H17.25C17.7584 31.5758 18.2459 31.3739 18.6053 31.0145C18.9648 30.655 19.1667 30.1675 19.1667 29.6592V18.1592L13.4167 12.4092Z"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.4166 12.4092V18.1592H19.1666"
			stroke="#805AD5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.3333 22.9507H7.66663"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.3333 26.7842H7.66663"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<rect
			width="23"
			height="23"
			transform="translate(10 1.49243)"
			fill="white"
		/>
		<path
			d="M23.4167 3.40918H15.75C15.2417 3.40918 14.7542 3.61111 14.3948 3.97056C14.0353 4.33 13.8334 4.81751 13.8334 5.32585V20.6592C13.8334 21.1675 14.0353 21.655 14.3948 22.0145C14.7542 22.3739 15.2417 22.5758 15.75 22.5758H27.25C27.7584 22.5758 28.2459 22.3739 28.6053 22.0145C28.9648 21.655 29.1667 21.1675 29.1667 20.6592V9.15918L23.4167 3.40918Z"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M23.4166 3.40918V9.15918H29.1666"
			stroke="#805AD5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M25.3333 13.9507H17.6666"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M25.3333 17.7842H17.6666"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'AnotherCustomSVGIcon',
}
</script>

<style scoped>
/* Add any necessary styles here */
</style>
