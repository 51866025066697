<template>
	<svg
		height="23"
		width="23"
		fill="none"
		viewBox="0 0 23 23"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
			stroke="#4A5568"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 13.9V7"
			stroke="#4A5568"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.5996 13.9V7"
			stroke="#4A5568"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		viewBox: {
			type: String,
			default: '0 0 24 24',
		},
		color: {
			type: String,
			default: 'currentColor',
		},
	},
}
</script>
