<template>
	<svg
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_4638_12026)">
			<path
				d="M13.4166 1.90909H5.74992C5.24159 1.90909 4.75408 2.11102 4.39463 2.47047C4.03519 2.82991 3.83325 3.31742 3.83325 3.82575V19.1591C3.83325 19.6674 4.03519 20.1549 4.39463 20.5144C4.75408 20.8738 5.24159 21.0758 5.74992 21.0758H17.2499C17.7583 21.0758 18.2458 20.8738 18.6052 20.5144C18.9647 20.1549 19.1666 19.6674 19.1666 19.1591V7.65909L13.4166 1.90909Z"
				stroke="#D21E29"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.4167 1.90909V7.65909H19.1667"
				stroke="#D21E29"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.3334 12.4508H7.66675"
				stroke="#D21E29"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.3334 16.2841H7.66675"
				stroke="#D21E29"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M28.75 28.7424L0 -0.00756836"
				stroke="#D21E29"
				stroke-width="1.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4638_12026">
				<rect
					width="23"
					height="23"
					fill="white"
					transform="translate(0 -0.00756836)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	props: {
		is_EMR_Configured: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
