<template>
	<div class="call-type">
		<button
			id="emr-move-toggle"
			class="call-action"
			aria-haspopup="listbox"
			aria-label="EMR filter"
			@click.prevent.stop="onToggleClick"
		>
			<span>Send to EMR </span>
			<font-awesome-icon
				class="emr-move-icon"
				:icon="['fal', show ? 'chevron-up' : 'chevron-down']"
			/>
		</button>
		<transition name="fade">
			<div v-if="show" class="move-emr-dropdown" :class="{ show }">
				<div
					id="emr-move-list"
					tabindex="-1"
					role="listbox"
					aria-label="Choose an EMR option"
					aria-multiselectable="true"
					class="move-emr-dropdown-list"
					@click.stop
				>
					<div class="call-type-container">
						<div
							v-for="option in sortedEmrOptions"
							:key="option.note_id"
							class="emr-move-item"
							@click="onEMRChange(option)"
						>
							<div class="emr-option">
								{{ option.note_name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import get from 'lodash/get'
const ESCAPE_KEY_CODE = 27

export default {
	computed: {
		/**
		 * Sort EMR options.
		 *
		 */
		sortedEmrOptions() {
			return this.emrOptions.slice().sort((a, b) => {
				return a.note_name.localeCompare(b.note_name)
			})
		},
	},
	methods: {
		/**
		 * add Event Listeners.
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Escape KeyCode.
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Escape KeyDown event handling.
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false
				this.removeEventListeners()
			}
		},

		/**
		 * onOutsideClick event handling.
		 */
		onOutsideClick(event) {
			if (this.show && !this.$el.contains(event.target)) {
				this.show = false
				this.removeEventListeners()
			}
		},

		/**
		 * onToggleClick event handling.
		 */
		onToggleClick() {
			this.show = !this.show
			this.show ? this.addEventListeners() : this.removeEventListeners()
		},

		/**
		 * remove Event Listeners.
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},

		/**
		 * On EMR change handling.
		 */
		async onEMRChange(option) {
			this.show = false
			const payload = {
				note_type: option.note_id,
				call_id: this.call.id,
			}

			const mrn = get(this.call, 'call_patient_details.mrn', null)

			if (!mrn) {
				this.$alert.error('MRN is not available for this call.')
				console.error(
					'onEMRChange: MRN is not available in call_patient_details.'
				)
				return
			}

			try {
				const response = await fetch(
					`${process.env.VUE_APP_API_URL}/api/v4/emr/clients/${mrn}/notes?partner_id=${this.$route.params.partner}`,
					{
						method: 'POST',
						headers: {
							Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(payload),
					}
				)

				const data = await response.json()

				if (response.ok) {
					if (data.success) {
						this.$alert.success(
							`Note Sent to EMR for ${option.note_name}`
						)
					} else {
						console.warn(
							'Failed to send coordination note:',
							data.errors
						)
						this.$alert.error('Failed to select EMR option.')
					}
				} else {
					throw new Error(
						'Failed to send coordination note: ' +
							(data.errors
								? data.errors.message
								: 'Unknown error')
					)
				}
			} catch (error) {
				console.error(
					'onEMRChange: Error sending coordination note',
					error
				)
				this.$alert.error('Failed to select EMR option.')
			}
		},

		/**
		 * Handling EMR dropdown.
		 */
		adjustDropdownPosition() {
			const dropdown = this.$el.querySelector('.move-emr-dropdown')
			const parentRect = this.$el.getBoundingClientRect()

			if (dropdown) {
				dropdown.style.top = `${parentRect.bottom}px`
				dropdown.style.left = `${parentRect.left}px`
			}
		},
	},
	name: 'SendToEmr',
	props: {
		call: {
			type: Object,
			required: true,
		},
		emrOptions: {
			type: Array,
			required: true,
		},
	},
	watch: {
		call() {
			const options = this.call.emrOptions
			if (!options || options.length === 0) {
				return
			}
			this.selectedEMROption = options[0].id
		},
	},

	mounted() {
		this.adjustDropdownPosition()
		window.addEventListener('scroll', this.adjustDropdownPosition, true)
	},

	beforeDestroy() {
		this.removeEventListeners()
		window.removeEventListener('scroll', this.adjustDropdownPosition, true)
	},

	data() {
		return {
			show: false,
		}
	},
}
</script>

<style scoped>
.move-emr-dropdown {
	margin-top: 16px;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	display: none;
	margin: 0.125rem 0 0;
	max-height: 18em;
	min-width: 10rem;
	position: fixed;
	z-index: 200;
	&.show {
		display: flex;
		flex-direction: column;
	}
}

.move-emr-dropdown-list {
	background-color: white;
	color: #343a40;
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	overflow-y: auto;
	padding: 0;
	text-align: left;
	width: 100%;
}

.call-type-container {
	padding: 0;
	width: 100%;
}

.emr-move-item {
	position: relative;
	cursor: pointer;
}

.emr-move-item:not(:last-child)::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #ccc;
}

.emr-option {
	text-align: left;
	font-weight: 450;
	padding: 12px 16px;
	&:hover {
		background-color: #e1e1e1;
	}
}
</style>
