<template>
	<panel :show="show" @close="close">
		<template #title>
			Complete Call
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-checkboxes.svg"
					class="panel-illustration"
				/>
				<span class="mb-4 font-roboto text-center">
					Please describe why this call was completed.
				</span>
				<textarea
					v-model="message"
					placeholder="Reason for completion (optional)."
					class="resize-none px-1 py-2 border rounded focus:outline-none focus:shadow-outline mb-4 font-roboto"
				></textarea>
				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
						<span class="btn-label">
							Complete
						</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active call.
		 *
		 * @return {Object}
		 */
		call() {
			return this.findBySid(this.sid)
		},

		/**
		 * Determine if a call is urgent.
		 *
		 * @return {Boolean}
		 */
		isUrgent() {
			if (this.call) {
				return this.call.is_urgent
			}

			return false
		},

		...mapGetters('calls', ['findBySid']),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				await this.complete({
					sid: this.sid,
					message: this.message,
				})

				this.message = ''
				this.$alert.success('calls.complete.success')
			} catch (e) {
				this.$alert.error('calls.complete.error')
			}

			this.close()
		},

		...mapActions({
			complete: 'calls/complete',
			get: 'calls/get',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CompleteCallPanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The call's sid to be dismissed.
		 *
		 * @type {String}
		 */
		sid: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			message: '',
		}
	},
}
</script>
