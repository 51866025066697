<template>
	<svg
		width="23"
		height="23"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 20.4632C4.48529 20.4632 0 15.9779 0 10.4632C0 4.94853 4.48529 0.5 10 0.5C15.5147 0.5 20 4.98529 20 10.5C20 16.0147 15.5147 20.5 10 20.5V20.4632ZM10 1.60294C5.07353 1.60294 1.10294 5.57353 1.10294 10.5C1.10294 15.4265 5.11029 19.3971 10 19.3971C14.8897 19.3971 18.8971 15.3897 18.8971 10.5C18.8971 5.61029 14.8897 1.60294 10 1.60294Z"
			:fill="color"
			:fill-opacity="opacity"
		/>
		<path
			d="M12.2427 7.30131C11.5441 6.86014 10.8456 6.63955 10.1103 6.56602V4.72778L7.13236 7.08072L10.1103 9.43367V7.63219C10.625 7.70572 11.1397 7.88955 11.6544 8.18367C12.8677 8.91896 13.5294 10.3528 13.3088 11.7498C13.1985 12.4116 12.9412 12.9998 12.5 13.4778C11.7279 14.2866 10.6618 14.6543 9.55883 14.5072C8.41912 14.3601 7.46324 13.6248 6.94854 12.6322C6.58089 11.8601 6.50736 11.3454 6.50736 11.3454H5.40442C5.40442 11.3454 5.55148 12.191 5.99265 13.1101C6.65442 14.4337 7.94118 15.3528 9.41177 15.5734C9.63236 15.5734 9.85295 15.6101 10.1103 15.6101C11.3235 15.6101 12.5 15.1322 13.3456 14.2131C13.9338 13.5881 14.3015 12.816 14.4485 11.9337C14.7059 10.0954 13.8603 8.29396 12.2794 7.30131H12.2427Z"
			:fill="color"
			:fill-opacity="opacity"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		opacity: {
			type: [Number, String],
			default: 0.6,
		},
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		viewBox: {
			type: String,
			default: '0 0 24 24',
		},
		color: {
			type: String,
			default: '#4A5568',
		},
	},
}
</script>
