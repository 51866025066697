<template>
	<svg
		class="ml-3"
		width="25"
		height="30"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.4168 1.90918H6.75016C6.24183 1.90918 5.75432 2.11111 5.39487 2.47056C5.03543 2.83 4.8335 3.31751 4.8335 3.82585V19.1592C4.8335 19.6675 5.03543 20.155 5.39487 20.5145C5.75432 20.8739 6.24183 21.0758 6.75016 21.0758H18.2502C18.7585 21.0758 19.246 20.8739 19.6055 20.5145C19.9649 20.155 20.1668 19.6675 20.1668 19.1592V7.65918L14.4168 1.90918Z"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.4165 1.90918V7.65918H20.1665"
			stroke="#805AD5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.3332 12.4507H8.6665"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.3332 16.2842H8.6665"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CustomSVGIcon',
}
</script>

<style scoped>
.ml-3 {
	margin-left: 1rem; /* Adjust margin-left as needed */
}
</style>
