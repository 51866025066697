<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 21.4924V19.4924C20 18.4316 19.5786 17.4142 18.8284 16.664C18.0783 15.9139 17.0609 15.4924 16 15.4924H8C6.93913 15.4924 5.92172 15.9139 5.17157 16.664C4.42143 17.4142 4 18.4316 4 19.4924V21.4924"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 11.4924C14.2091 11.4924 16 9.70157 16 7.49243C16 5.28329 14.2091 3.49243 12 3.49243C9.79086 3.49243 8 5.28329 8 7.49243C8 9.70157 9.79086 11.4924 12 11.4924Z"
			stroke="#805AD5"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: '24',
		},
		height: {
			type: String,
			default: '25',
		},
	},
}
</script>
