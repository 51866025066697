<template>
	<div>
		<button
			v-if="!isPlaying"
			type="button"
			data-tooltip="Play"
			data-position="bottom"
			@click.prevent="play"
		>
			<font-awesome-icon
				fixed-width
				:class="color ? `btn-icon ${color}` : 'btn-icon'"
				:icon="['fal', 'play-circle']"
			/>
		</button>
		<button
			v-else
			type="button"
			data-tooltip="Pause"
			data-position="bottom"
			@click.prevent="pause"
		>
			<font-awesome-icon
				fixed-width
				:class="color ? `btn-icon ${color}` : 'btn-icon'"
				:icon="['fal', 'pause-circle']"
			/>
		</button>
	</div>
</template>

<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the audio file has been completed.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return this.audio.currentTime === this.audio.duration
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Pause the given audio file.
		 *
		 * @return {void}
		 */
		pause() {
			if (!this.isComplete) {
				this.audio?.pause()

				this.isPlaying = false
			}
		},

		/**
		 * Play the given audio file.
		 *
		 * @return {void}
		 */
		async play() {
			if (this.canPlay) {
				await this.audio.play()
			}

			this.isPlaying = true
		},

		/**
		 * Handle the audio file ending.
		 *
		 * @return {void}
		 */
		onAudioEnded() {
			this.isPlaying = false
		},

		/**
		 * Handle the audio file error.
		 *
		 * @return {void}
		 */
		onAudioError() {
			// Create a new audio element as pre signed url expired.
			this.audio = new Audio(this.url)

			// Remove the old event listeners and add the new ones.
			this.audio.removeEventListener('error', this.onAudioError)
			this.audio.removeEventListener('ended', this.onAudioEnded)
			this.audio.removeEventListener(
				'canplaythrough',
				this.onCanPlayThrough
			)
			this.audio.addEventListener('error', this.onAudioError)
			this.audio.addEventListener('ended', this.onAudioEnded)
			this.audio.addEventListener('canplaythrough', this.onCanPlayThrough)
			this.play()
		},
		/**
		 * Handle the can play through event.
		 *
		 * @return {void}
		 */
		onCanPlayThrough() {
			this.canPlay = true
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'AudioPlayer',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The audio file's url.
		 *
		 * @type {String}
		 */
		url: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			required: false,
			default: '',
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the audio URL for changes.
		 *
		 * @param {String} url
		 * @param {String} previous
		 * @return {void}
		 */
		url(url, previous) {
			this.pause()

			if (url && url !== previous) {
				this.audio.src = url
			}
		},
	},

	/**
	 * The component's before create lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		if (this.isPlaying) {
			this.pause()
		}
		this.audio.removeEventListener('error', this.onAudioError)
		this.audio.removeEventListener('ended', this.onAudioEnded)
		this.audio.removeEventListener('canplaythrough', this.onCanPlayThrough)
	},

	/**
	 * The component's before create lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.audio.addEventListener('error', this.onAudioError)
		this.audio.addEventListener('ended', this.onAudioEnded)
		this.audio.addEventListener('canplaythrough', this.onCanPlayThrough)
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			audio: new Audio(this.url),
			canPlay: false,
			isPlaying: false,
		}
	},
}
</script>
